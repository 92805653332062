<template>
  <div>
    <b-row>
      <b-col>
        <div class="float-right mr-1">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            type="button"
            :variant="'primary'"
            class="mr-1"
            :disabled="actionObj.IsLegalHold || (userType === 'Investigator' && Number(UID) !== Number(activityObj.UserID))"
            @click.prevent="editActivity()"
          >
            Edit Activity
          </b-button>
        </div>
      </b-col>
    </b-row>

    <b-row class="pt-1">
      <b-col class="text-right">
        <small>Action Type: </small>
      </b-col>
      <b-col class="text-left">
        <small v-if="actionObj">{{ actionObj.ActionTypeName }}</small>
      </b-col>
      <b-col class="text-right">
        <small>Vehicle: </small>
      </b-col>
      <b-col class="text-left">
        <small>{{ VehicleName }}</small>
      </b-col>
    </b-row>

    <b-row v-if="POVVehicleName">
      <b-col md="6">
      </b-col>
      <b-col class="text-right">
        <small>POV: </small>
      </b-col>
      <b-col class="text-left">
        <small>{{ POVVehicleName }}</small>
      </b-col>
    </b-row>

    <b-row class="pt-2" v-if="activityObj.ActivityTravel && activityObj.ActivityTravel.To.EstimatedDistance && getCurrentScopes.indexOf('st2.betafeatures') !== -1">
      <b-col class="text-right">
        <small>Mileage To:</small>
      </b-col>
      <b-col class="text-left">
        <small>{{ activityObj.ActivityTravel && activityObj.ActivityTravel.To.ActualDistance || activityObj.ActivityTravel.To.EstimatedDistance }}</small>
      </b-col>
      <b-col class="text-right">
        <small>Mileage From:</small>
      </b-col>
      <b-col class="text-left">
        <small>{{ activityObj.ActivityTravel && activityObj.ActivityTravel.From.ActualDistance || activityObj.ActivityTravel.From.EstimatedDistance }}</small>
      </b-col>
    </b-row>

    <b-row
      v-if="activityObj.ActivityTravel && activityObj.ActivityTravel.To.EstimatedDistance &&
        ((activityObj.ActivityTravel.To.ActualDistance && activityObj.ActivityTravel.To.ActualDistance !== activityObj.ActivityTravel.To.EstimatedDistance) ||
          (activityObj.ActivityTravel.From.ActualDistance && activityObj.ActivityTravel.From.ActualDistance !== activityObj.ActivityTravel.From.EstimatedDistance)) &&
        getCurrentScopes.indexOf('st2.betafeatures') !== -1"
    >
      <b-col
        v-if="activityObj.ActivityTravel.To.ActualDistance && activityObj.ActivityTravel.To.ActualDistance !== activityObj.ActivityTravel.To.EstimatedDistance"
        class="text-right"
        md="3"
      >
        <small>Est Mileage To:</small>
      </b-col>
      <b-col
        v-if="activityObj.ActivityTravel.To.ActualDistance && activityObj.ActivityTravel.To.ActualDistance !== activityObj.ActivityTravel.To.EstimatedDistance"
        class="text-left"
      >
        <small>{{ activityObj.ActivityTravel && activityObj.ActivityTravel.To.EstimatedDistance }}</small>
      </b-col>
      <b-col v-else md="6"/>
      <b-col
        v-if="activityObj.ActivityTravel.From.ActualDistance && activityObj.ActivityTravel.From.ActualDistance !== activityObj.ActivityTravel.From.EstimatedDistance"
        class="text-right"
      >
        <small>Est Mileage From:</small>
      </b-col>
      <b-col
        v-if="activityObj.ActivityTravel.From.ActualDistance && activityObj.ActivityTravel.From.ActualDistance !== activityObj.ActivityTravel.From.EstimatedDistance"
        class="text-left"
      >
        <small>{{ activityObj.ActivityTravel && activityObj.ActivityTravel.From.EstimatedDistance }}</small>
      </b-col>
    </b-row>

    <b-row
      v-if="activityObj.ActivityTravel && activityObj.ActivityTravel.To.EstimatedDistance &&
        ((activityObj.ActivityTravel.To.ActualDistance && activityObj.ActivityTravel.To.ActualDistance !== activityObj.ActivityTravel.To.EstimatedDistance) ||
          (activityObj.ActivityTravel.From.ActualDistance && activityObj.ActivityTravel.From.ActualDistance !== activityObj.ActivityTravel.From.EstimatedDistance)) &&
        getCurrentScopes.indexOf('st2.betafeatures') !== -1"
    >
      <b-col
        v-if="activityObj.ActivityTravel.To.ActualDistance && activityObj.ActivityTravel.To.ActualDistance !== activityObj.ActivityTravel.To.EstimatedDistance"
        class="text-right"
        md="3"
      >
        <small>Reason:</small>
      </b-col>
      <b-col
        v-if="activityObj.ActivityTravel.To.ActualDistance && activityObj.ActivityTravel.To.ActualDistance !== activityObj.ActivityTravel.To.EstimatedDistance"
        class="text-left"
      >
        <small>{{ activityObj.ActivityTravel && activityObj.ActivityTravel.To.ActualReason }}</small>
      </b-col>
      <b-col v-else md="6"/>
      <b-col
        v-if="activityObj.ActivityTravel.From.ActualDistance && activityObj.ActivityTravel.From.ActualDistance !== activityObj.ActivityTravel.From.EstimatedDistance"
        class="text-right"
      >
        <small>Reason:</small>
      </b-col>
      <b-col
        v-if="activityObj.ActivityTravel.From.ActualDistance && activityObj.ActivityTravel.From.ActualDistance !== activityObj.ActivityTravel.From.EstimatedDistance"
        class="text-left"
      >
        <small>{{ activityObj.ActivityTravel && activityObj.ActivityTravel.From.ActualReason }}</small>
      </b-col>
    </b-row>

    <b-row v-if="getCurrentScopes.indexOf('st2.betafeatures') !== -1">
      <b-col class="text-right">
        <small>Actual Drive Time To:</small>
      </b-col>
      <b-col class="text-left">
        <small>{{ parseMinutesToHours(ActualDriveTimeTo) }}</small>
      </b-col>
      <b-col class="text-right">
        <small>Actual Drive Time From:</small>
      </b-col>
      <b-col class="text-left">
        <small>{{ parseMinutesToHours(ActualDriveTimeFrom) }}</small>
      </b-col>
    </b-row>

    <b-row
      v-if="activityObj.ActivityTravel && activityObj.ActivityTravel.To.TimePerDistance &&
        getCurrentScopes.indexOf('st2.betafeatures') !== -1 &&
        getCurrentScopes.indexOf('st2.viewcalculatedtravelactivity') !== -1"
    >
      <b-col class="text-right">
        <small>Est Drive Time To:</small>
      </b-col>
      <b-col class="text-left">
        <small>{{ activityObj.ActivityTravel && parseMinutesToHours(activityObj.ActivityTravel.To.TimePerDistance) }}</small>
      </b-col>
      <b-col class="text-right">
        <small>Est Drive Time From:</small>
      </b-col>
      <b-col class="text-left">
        <small>{{ activityObj.ActivityTravel && parseMinutesToHours(activityObj.ActivityTravel.From.TimePerDistance) }}</small>
      </b-col>
    </b-row>

    <b-row
      v-if="activityObj.ActivityTravel && activityObj.ActivityTravel.To.EstimatedTimeMinutes &&
        getCurrentScopes.indexOf('st2.betafeatures') !== -1 &&
        getCurrentScopes.indexOf('st2.viewcalculatedtravelactivity') !== -1"
    >
      <b-col class="text-right">
        <small>Mapped Drive Time To:</small>
      </b-col>
      <b-col class="text-left">
        <small>{{ activityObj.ActivityTravel && parseMinutesToHours(activityObj.ActivityTravel.To.EstimatedTimeMinutes) }}</small>
      </b-col>
      <b-col class="text-right">
        <small>Mapped Drive Time From:</small>
      </b-col>
      <b-col class="text-left">
        <small>{{ activityObj.ActivityTravel && parseMinutesToHours(activityObj.ActivityTravel.From.EstimatedTimeMinutes) }}</small>
      </b-col>
    </b-row>

    <b-row class="pt-2">
      <b-col class="text-right">
        <small>Activity Begin Time:</small>
      </b-col>
      <b-col class="text-left">
        <small>{{ activityObj.BeginTime | dateFormat }}</small>
      </b-col>
      <b-col class="text-right">
        <small>On Site Arrival Time:</small>
      </b-col>
      <b-col class="text-left">
        <small>{{ activityObj.OnSiteArrivalTime | dateFormat }}</small>
      </b-col>
    </b-row>

    <b-row class="pb-1">
      <b-col class="text-right">
        <small>Activity End Time: </small>
      </b-col>
      <b-col class="text-left">
        <small>{{ activityObj.EndTime | dateFormat }}</small>
      </b-col>
      <b-col class="text-right">
        <small>On Site Departure Time: </small>
      </b-col>
      <b-col class="text-left">
        <small>{{ activityObj.OnSiteDepartureTime | dateFormat }}</small>
      </b-col>
    </b-row>

    <b-row class="pt-1">
      <b-col class="text-right">
        <small>Hotel Stay: </small>
      </b-col>
      <b-col class="text-left">
        <small>
          <strong>
            {{ activityObj.HotelStay | yesNoFilter }}
          </strong>
        </small>
      </b-col>
      <b-col class="text-right">
        <small>Mileage: </small>
      </b-col>
      <b-col class="text-left">
        <small>
          <strong>
            {{ activityObj.Mileage | numberCount }}
          </strong>
        </small>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="text-right">
        <small>Integrity Only: </small>
      </b-col>
      <b-col class="text-left">
        <small>
          <strong>
            {{ activityObj.IntegrityOnly | yesNoFilter }}
          </strong>
        </small>
      </b-col>
      <b-col class="text-right overflow-hidden align-self-center">
        <small class="text-nowrap width-inherit d-block overflow-hidden">Video Vehicle/Camcorder, Video Mins Obtained: </small>
      </b-col>
      <b-col class="text-left">
        <small>
          <strong>
            {{ activityObj.VideoObtainedMinsVehicle | numberCount }}
          </strong>
        </small>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="text-right">
        <small>Claimant Video Obtained: </small>
      </b-col>
      <b-col class="text-left">
        <small>
          <strong>
            {{ activityObj.VideoObtained | yesNoFilter }}
          </strong>
        </small>
      </b-col>
      <b-col class="text-right">
        <small>Video Covert Mins Obtained: </small>
      </b-col>
      <b-col class="text-left">
        <small>
          <strong>
            {{ activityObj.VideoObtainedMinsCovert | numberCount }}
          </strong>
        </small>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        class="text-right"
        md="3"
      >
        <small>Audio Obtained: </small>
      </b-col>
      <b-col class="text-left">
        <small>
          <strong>
            {{ activityObj.AudioObtained | yesNoFilter }}
          </strong>
        </small>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        class="text-right"
        md="3"
      >
        <small>Compromise by Target: </small>
      </b-col>
      <b-col
        class="text-left"
        md="3"
      >
        <small>
          <strong>
            {{ activityObj.CompromiseByTarget | yesNoFilter }}
          </strong>
        </small>
      </b-col>
      <b-col class="text-right">
        <small>Total Video Clip Count: </small>
      </b-col>
      <b-col class="text-left">
        <small>
          <strong>
            {{ activityObj.VideoTotalClipCount | numberCount }}
          </strong>
        </small>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        class="text-right"
        md="3"
      >
        <small>Mobile Surveillance Conducted: </small>
      </b-col>
      <b-col
        class="text-left"
        md="3"
      >
        <small>
          <strong>
            {{ activityObj.MobileSurveillanceConducted | yesNoFilter }}
          </strong>
        </small>
      </b-col>
    </b-row>

    <b-row>
      <b-col
        class="text-right"
        md="3"
      >
        <small>Mobile Surveillance Lost: </small>
      </b-col>
      <b-col
        class="text-left"
        md="3"
      >
        <small>
          <strong>
            {{ activityObj.MobileSurveillanceLost | yesNoFilter }}
          </strong>
        </small>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="text-right">
        <small>Mobile Surveillance Recovery: </small>
      </b-col>
      <b-col class="text-left">
        <small>
          <strong>
            {{ activityObj.MobileSurveillanceRecovery | yesNoFilter }}
          </strong>
        </small>
      </b-col>
      <b-col class="text-right">
        <small>Recovery Explanation: </small>
      </b-col>
      <b-col class="text-left">
        <small>
          <strong>
            {{ activityObj.MobileSurveillanceRecoveryExplanation }}
          </strong>
        </small>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="text-right" />
      <b-col class="text-left" />
      <b-col class="text-right">
        <small>Rating: </small>
      </b-col>
      <b-col class="text-left">
        <small>
          <strong>
            {{ activityObj.Rating }}
          </strong>
        </small>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="text-right">
        <small>15 minutes for Case Writing work: </small>
      </b-col>
      <b-col class="text-left">
        <small>
          <strong>
            {{ activityObj.IsCaseWritingWork15Minutes | yesNoFilter }}
          </strong>
        </small>
      </b-col>
      <b-col class="text-right">
        <small>Minutes over 15: </small>
      </b-col>
      <b-col class="text-left">
        <small>
          <strong>
            {{ activityObj.MinutesOver15 }}
          </strong>
        </small>
      </b-col>
    </b-row>

    <b-row>
      <b-col class="text-right">
        <small>Additional time over 15 minutes needed: </small>
      </b-col>
      <b-col class="text-left">
        <small>
          <strong>
            {{ activityObj.IsAdditionalTimeOver15Minutes | yesNoFilter }}
          </strong>
        </small>
      </b-col>
      <b-col class="text-right">
        <small>Reason: </small>
      </b-col>
      <b-col class="text-left">
        <small>
          <strong>
            {{ activityObj.MinutesOver15Reason }}
          </strong>
        </small>
      </b-col>
    </b-row>

  </div>
</template>

<script>
import {BButton, BCol, BRow} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import APIService from "@core/utils/APIService";
import {mapGetters} from "vuex";

const apiService = new APIService();

export default {
  components: {
    BRow,
    BCol,
    BButton,
  },
  directives: {
    Ripple,
  },
  filters: {
    dateFormat(val) {
      if (val) {
        const date = new Date(val);
        const options = {year: 'numeric', month: '2-digit', day: '2-digit'}
        const timeOptions = { hour: '2-digit', minute: '2-digit'};
        const Time = date.toLocaleTimeString("en-US", timeOptions);
        if (date) return date.toLocaleDateString("en-US", options) + " " + Time;
      }
      return null;
    },
    yesNoFilter(val) {
      return val ? "Yes" : "No";
    },
    numberCount(val) {
      return val ? val : 0;
    }
  },
  props: {
    activityObj: {
      type: Object,
      default: () => {},
    },
    actionObj: {
      type: Object,
      default: () => {},
    }
  },
  data() {
    return {
      VehicleName: "N/A - Not Applicable",
      POVVehicleName: "",
      userType: "",
      UID: "",
      ActualDriveTimeTo: "",
      ActualDriveTimeFrom: "",
    }
  },
  computed: {
    ...mapGetters({
      getUserType: "auth/getUserType",
      getCurrentScopes: "scopes/getCurrentScopes",
    }),
  },
  watch: {
    activityObj: {
      handler(val) {
        if (val.UserID) {
          this.getPOVList(val.UserID);
          this.getVehicleList();
        }
        if (val.BeginTime) {
          this.getActualDriveTime(val.BeginTime, val.OnSiteArrivalTime, val.OnSiteDepartureTime, val.EndTime);
        }
      },
      deep: true,
    }
  },
  mounted() {
    this.userType = localStorage.getItem("userType");
    this.UID = localStorage.getItem("UID");
  },
  async created() {
    this.getVehicleList();
    this.getPOVList(this.activityObj.UserID);
    this.getActualDriveTime(this.activityObj.BeginTime, this.activityObj.OnSiteArrivalTime, this.activityObj.OnSiteDepartureTime, this.activityObj.EndTime);
  },
  methods: {
    editActivity() {
      this.$router.push('/cases/edit-activity/' + this.activityObj.ActivityID);
    },

    getVehicleList() {
      apiService
          .get("vehicles/list")
          .then(res => {
            const Vehicle = res.data.filter(i => i.VehicleID === this.activityObj.VehicleID)[0];
            if (this.activityObj.VehicleID && Vehicle) {
              this.VehicleName = Vehicle.Name
            }
          })
    },

    getPOVList(UserID) {
      if (!UserID) return
      apiService.get("user/" + UserID + "/vehicles")
          .then(res => {
            if (res.data && res.data.length) {
              const Vehicle = res.data.filter(i => i.id === this.activityObj.UserOwnVehicleID)[0];
              if (this.activityObj.UserOwnVehicleID && Vehicle) {
                this.POVVehicleName = (Vehicle.year ? Vehicle.year : "") + " " + (Vehicle.manufacture ? Vehicle.manufacture : "") + " " + (Vehicle.model ? Vehicle.model : "")
              }
            }
          })
    },

    getActualDriveTime(ActivityBegin, OnSiteArrival, OnSiteDeparture, ActivityEnd) {
      this.ActualDriveTimeTo = Math.round((new Date(OnSiteArrival).getTime() - new Date(ActivityBegin).getTime()) / (1000 * 60));
      this.ActualDriveTimeFrom = Math.round((new Date(ActivityEnd).getTime() - new Date(OnSiteDeparture).getTime()) / (1000 * 60));
    },

    parseMinutesToHours(minutes) {
      if (!minutes) return "- h - - min"
      const hours = Math.floor(minutes / 60);
      const remainingMinutes = minutes % 60;
      return `${hours} h ${remainingMinutes} min`;
    },
  }
}
</script>

<style scoped>

</style>